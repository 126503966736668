import { css, html, LitElement } from 'lit';

import { NovaPermissionMixin } from '../../../mixins/nova-permission-mixin/nova-permission-mixin.js';

class NovaPermissionContainer extends NovaPermissionMixin(LitElement) {
  static get properties() {
    return {
      // Add properties here
    };
  }

  static get styles() {
    return css`
      /* Add styles here */
    `;
  }

  allowedRender() {
    return html`
      <slot></slot>
    `;
  }
}

window.customElements.define('nova-permission-container', NovaPermissionContainer);

