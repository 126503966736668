export const FULL_PERMISSIONS = {
  ACTIVITY_CREATE: {
    name: 'activity:create',
    category: 'activity',
  },
  ACTIVITY_VIEW: {
    name: 'activity:view',
    category: 'activity',
  },
  ACTIVITY_STATUS_UPDATE: {
    name: 'activity:status:update',
    category: 'activity',
  },
  ACTIVITY_EXPORT: {
    name: 'activity:export',
    category: 'activity',
  },
  ACTIVITY_STREAM_IMPORT: {
    name: 'activity:streams:import',
    category: 'activity',
  },
  ACTIVITY_ARCHIVE: {
    name: 'activity:archive',
    category: 'activity',
  },
  PROVIDER_GENERAL_VIEW: {
    name: 'provider:general:view',
    category: 'provider:view',
  },
  PROVIDER_FINANCE_VIEW: {
    name: 'provider:finance:view',
    category: 'provider:view',
  },
  PROVIDER_HOWITWORKS_VIEW: {
    name: 'provider:howitworks:view',
    category: 'provider:view',
  },
  PROVIDER_CUSTOMATTRIBUTES_VIEW: {
    name: 'provider:customattributes:view',
    category: 'provider:view',
  },
  PROVIDER_FAQ_VIEW: {
    name: 'provider:faq:view',
    category: 'provider:view',
  },
  PROVIDER_ACTIVITYINSTRUCTIONS_VIEW: {
    name: 'provider:activityinstructions:view',
    category: 'provider:view',
  },
  PROVIDER_ENTITLEMENTS_VIEW: {
    name: 'provider:entitlements:view',
    category: 'provider:view',
  },
  PROVIDER_LOGIN_VIEW: {
    name: 'provider:login:view',
    category: 'provider:view',
  },
  PROVIDER_AUDIT_VIEW: {
    name: 'provider:audit:view',
    category: 'provider:view',
  },
  PROVIDER_GENERAL_UPDATE: {
    name: 'provider:general:update',
    category: 'provider:update',
  },
  PROVIDER_FINANCE_UPDATE: {
    name: 'provider:finance:update',
    category: 'provider:update',
  },
  PROVIDER_HOWITWORKS_UPDATE: {
    name: 'provider:howitworks:update',
    category: 'provider:update',
  },
  PROVIDER_CUSTOMATTRIBUTES_UPDATE: {
    name: 'provider:customattributes:update',
    category: 'provider:update',
  },
  PROVIDER_FAQ_UPDATE: {
    name: 'provider:faq:update',
    category: 'provider:update',
  },
  PROVIDER_ACTIVITYINSTRUCTIONS_UPDATE: {
    name: 'provider:activityinstructions:update',
    category: 'provider:update',
  },
  PROVIDER_ENTITLEMENTS_UPDATE: {
    name: 'provider:entitlements:update',
    category: 'provider:update',
  },
  PROVIDER_LOGIN_UPDATE: {
    name: 'provider:login:update',
    category: 'provider:update',
  },
  PROVIDER_AUDIT_UPDATE: {
    name: 'provider:audit:update',
    category: 'provider:update',
  },
  PROVIDER_CREATE: {
    name: 'provider:create',
    category: 'provider',
  },

  EMPLOYER_GENERAL_VIEW: {
    name: 'employer:general:view',
    category: 'employer:view',
  },
  EMPLOYER_CAREEREXPLORER_VIEW: {
    name: 'employer:careerexplorer:view',
    category: 'employer:view',
  },
  EMPLOYER_FINANCE_VIEW: {
    name: 'employer:finance:view',
    category: 'employer:view',
  },
  EMPLOYER_HOWITWORKS_VIEW: {
    name: 'employer:howitworks:view',
    category: 'employer:view',
  },
  EMPLOYER_CUSTOMATTRIBUTES_VIEW: {
    name: 'employer:customattributes:view',
    category: 'employer:view',
  },
  EMPLOYER_FAQ_VIEW: {
    name: 'employer:faq:view',
    category: 'employer:view',
  },
  EMPLOYER_TERMSOFUSE_VIEW: {
    name: 'employer:termsofuse:view',
    category: 'employer:view',
  },
  EMPLOYER_ENTITLEMENTS_VIEW: {
    name: 'employer:entitlements:view',
    category: 'employer:view',
  },
  EMPLOYER_LOGIN_VIEW: {
    name: 'employer:login:view',
    category: 'employer:view',
  },
  EMPLOYER_VISIBILITY_VIEW: {
    name: 'employer:visibility:view',
    category: 'employer:view',
  },
  EMPLOYER_ENABLEDSTREAMS_VIEW: {
    name: 'employer:enabledstreams:view',
    category: 'employer:update',
  },
  EMPLOYER_AUDIT_VIEW: {
    name: 'employer:audit:view',
    category: 'employer:view',
  },
  EMPLOYER_GENERAL_UPDATE: {
    name: 'employer:general:update',
    category: 'employer:update',
  },
  EMPLOYER_CAREEREXPLORER_UPDATE: {
    name: 'employer:careerexplorer:update',
    category: 'employer:update',
  },
  EMPLOYER_FINANCE_UPDATE: {
    name: 'employer:finance:update',
    category: 'employer:update',
  },
  EMPLOYER_HOWITWORKS_UPDATE: {
    name: 'employer:howitworks:update',
    category: 'employer:update',
  },
  EMPLOYER_CUSTOMATTRIBUTES_UPDATE: {
    name: 'employer:customattributes:update',
    category: 'employer:update',
  },
  EMPLOYER_FAQ_UPDATE: {
    name: 'employer:faq:update',
    category: 'employer:update',
  },
  EMPLOYER_TERMSOFUSE_UPDATE: {
    name: 'employer:termsofuse:update',
    category: 'employer:update',
  },
  EMPLOYER_ENTITLEMENTS_UPDATE: {
    name: 'employer:entitlements:update',
    category: 'employer:update',
  },
  EMPLOYER_LOGIN_UPDATE: {
    name: 'employer:login:update',
    category: 'employer:update',
  },
  EMPLOYER_VISIBILITY_UPDATE: {
    name: 'employer:visibility:update',
    category: 'employer:update',
  },
  EMPLOYER_ENABLEDSTREAMS_UPDATE: {
    name: 'employer:enabledstreams:update',
    category: 'employer:update',
  },
  EMPLOYER_AUDIT_UPDATE: {
    name: 'employer:audit:update',
    category: 'employer:update',
  },
  EMPLOYER_CREATE: {
    name: 'employer:create',
    category: 'employer',
  },
  FEATURES_UPDATE: {
    name: 'features:update',
    category: 'features',
  },
  FEATURES_VIEW: {
    name: 'features:view',
    category: 'features',
  },
  REQUESTS_VIEW: {
    name: 'requests:view',
    category: 'requests',
  },
  REQUESTS_UPDATE: {
    name: 'requests:update',
    category: 'requests',
  },
  ANALYTICS_VIEW: {
    name: 'analytics:view',
    category: 'analytics',
  },
  PERMISSIONS: {
    name: 'permissions',
    category: 'permissions',
  },
};

// Create an object with just the permission names
export const PERMISSIONS = Object.keys(FULL_PERMISSIONS).reduce((acc, key) => {
  acc[key] = FULL_PERMISSIONS[key].name;
  return acc;
}, {});

// Build the hierarchical permissions structure
export const PERMISSIONS_BY_CATEGORY = Object.values(FULL_PERMISSIONS).reduce((acc, permission) => {
  const [category, subcategory] = permission.category.split(':');

  if (!acc[category]) {
    acc[category] = {
      permissions: [],
      categories: {},
    };
  }

  if (subcategory) {
    if (!acc[category].categories[permission.category]) {
      acc[category].categories[permission.category] = {
        permissions: [],
      };
    }
    acc[category].categories[permission.category].permissions.push(permission.name);
  } else {
    acc[category].permissions.push(permission.name);
  }

  return acc;
}, {});
